import { useState, useRef, useEffect } from 'react'
import { isValid, format } from 'date-fns'
import { it } from 'date-fns/locale'

import DatePicker from 'react-datepicker'
import { CalendarOutlined, InfoCircleOutlined } from '@ant-design/icons'

import './DatePicker.scss'
import 'react-datepicker/dist/react-datepicker.css'

const currentDate = new Date()

// Zero out the time component for a fair comparison
currentDate.setHours(0, 0, 0, 0)

function parseDateString(dateStr) {
  const parts = dateStr?.split('/')
  if (parts?.length !== 3) return null

  const [day, month, year] = parts.map((part) => parseInt(part, 10))

  // Basic validation
  if (
    isNaN(day) ||
    isNaN(month) ||
    isNaN(year) ||
    day < 1 ||
    day > 31 ||
    month < 1 ||
    month > 12 ||
    year < 1900 ||
    year > 2100 // Assuming dates are within 1900-2100 range
  ) {
    return null
  }

  const date = new Date(year, month - 1, day)

  // Check if the date is valid and matches the input
  if (!isValid(date) || format(date, 'dd/MM/yyyy') !== dateStr) {
    return null
  }

  return date
}

const DatePickerInput = ({
  label,
  name,
  validation,
  dateValue,
  setDateValue,
  error,
}) => {
  const datePickerRef = useRef(null)

  const initialDateString = dateValue
  const initialDate = parseDateString(initialDateString)

  const [inputValue, setInputValue] = useState(initialDateString)
  const [startDate, setStartDate] = useState(initialDate)
  const [openDatePicker, setOpenDatePicker] = useState(false)
  const [isValidDate, setIsValidDate] = useState(true)

  useEffect(() => {
    const initialDateString = dateValue
    const initialDate = parseDateString(initialDateString)

    setInputValue(initialDateString)
    setStartDate(initialDate)
  }, [dateValue])

  const handleInputChange = (e) => {
    let value = e.target.value
    const previousValue = inputValue

    if (value?.length > 10) {
      value = previousValue // revert to the previous value
    } else {
      // Filter out non-numeric and non-slash characters
      value = value?.replace(/[^0-9/]/g, '')

      // If the user is typing (not deleting)
      if (value?.length > previousValue?.length) {
        /**
         * CASE TO CHECK IF USER ENTER INVALID DAY IF LENGTH > 8
         */
        if (value.length >= 8 && Number(value.split('/')[0]) > 31) {
          const day = value.substring(0, 2)
          const monthAndYear = value.substring(2)
          value = `0${day.charAt(1)}${monthAndYear}`
        } else if (value.length >= 8 && Number(value.split('/')[1]) > 12) {
          /**
           * CASE TO CHECK IF USER ENTER INVALID DAY IF LENGTH > 8
           */
          const parts = value.split('/')
          const day = parts[0]
          const month = parts[1]
          const year = parts[2]

          value = `${day}/0${month.charAt(1)}/${year}`
        }

        /**
         * 2 CASES TO PREVENT USER TO TYPE 0 IN POSITION 1 and 4
         */
        // else if (value.length === 1 && value.charAt(0) === '0') {
        //   value = ''
        // }
        // else if (value.length === 4 && value.charAt(3) === '0') {
        //   value = value.substring(0, 3)
        // }

        /**
         * CASE TO PREVENT USER TO TYPE / IN POSITION 4
         */
        else if (value.length === 4 && value.charAt(3) === '/') {
          value = value.substring(0, 3)
        }

        /**
         * CASE TO PREVENT USER TO TYPE / IN POSITION 4
         */
        if (value.length === 7 && value.charAt(6) === '/') {
          value = value.substring(0, 6)
        }

        /**
         * 4 CASES TO HANDLE DAY
         */
        // Add "0" before single digit day if the value > 1 and length === 1
        if (value.length === 1 && value.charAt(0) > 3) {
          value = '0' + value + '/'
        } else if (
          value.length === 2 &&
          value.charAt(0) === 3 &&
          value.charAt(1) > 1
        ) {
          value = '0' + value.charAt(1) + '/'
        }
        // Add "0" before single digit day and add "/"
        else if (value.length === 2 && value.charAt(1) === '/') {
          value = '0' + value
        }
        // Add "/" after 2 digits if it doesn't exist
        else if (value.length === 2 && !value.includes('/')) {
          value += '/'
        }

        /**
         * 4 CASES TO HANDLE MONTH
         */
        // Add "/" after typing the first digit of month if only the value of > 1
        else if (
          value.length === 4 &&
          value.charAt(2) === '/' &&
          value.charAt(3) > 1
        ) {
          value = value.slice(0, 2) + '/0' + value.charAt(3) + '/'
        }
        // 02/19
        // if month number from 13 to 19 replace this number with 0[second digit]
        else if (value.length === 5 && Number(value.split('/')[1]) > 12) {
          value = value.slice(0, 2) + '/0' + value.charAt(4) + '/'
        }
        // Add "/" after typing the first digit of month, but only if there isn't a slash in position 3
        else if (
          value.length === 5 &&
          value.charAt(2) === '/' &&
          value.charAt(4) !== '/'
        ) {
          value = value.slice(0, 5) + '/'
        }
        // Add "0" before single digit month when it ends with '/'
        else if (value.length === 5 && value.charAt(4) === '/') {
          value = value.slice(0, 2) + '/0' + value.charAt(3) + '/'
        }
      }
    }

    setInputValue(value) // Update the inputValue state
    setDateValue(value)

    const parsedDate = parseDateString(value)
    if (parsedDate) {
      setStartDate(parsedDate)
      setIsValidDate(true)
    } else if (!value) {
      setStartDate(null)
      setIsValidDate(true) // consider empty input as valid for this case
    }

    // Set invalid-date class if the date is not valid
    // We have three cases:
    // 1- gt 2- lt 3- Not valid
    if (value?.length === 10 && parsedDate) {
      if (validation === 'gt' && parsedDate <= currentDate) {
        setIsValidDate(false)
      }
      if (validation === 'lt' && parsedDate >= currentDate) {
        setIsValidDate(false)
      }
    }
  }

  const handleDateChange = (date) => {
    if (date && isValid(date)) {
      const formattedDate = format(date, 'dd/MM/yyyy')
      setInputValue(formattedDate)
      setDateValue(formattedDate)
      setStartDate(date)
    } else {
      setInputValue('')
      setDateValue('')
      setStartDate(null)
    }
    setOpenDatePicker(false)
  }

  useEffect(() => {
    // Function to check if clicked outside of ref
    const handleClickOutside = (event) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setOpenDatePicker(false)
      }
    }

    // Add the listener to the document
    document.addEventListener('mousedown', handleClickOutside)

    // Clean up listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, []) // The empty array means this useEffect runs once when the component mounts and cleans up when it unmounts

  return (
    <div className='date-picker'>
      {label && (
        <div className='d-flex align-items-center gap-2 mb-2'>
          <label className='mb-0' htmlFor={name}>
            {label}
          </label>
          {error && <InfoCircleOutlined className='text-danger' />}
        </div>
      )}
      <div ref={datePickerRef}>
        <DatePicker
          showIcon
          icon={
            <CalendarOutlined
              style={{ fontSize: '1.25rem', color: '#8FC045' }}
              onClick={() => setOpenDatePicker(!openDatePicker)}
            />
          }
          locale={it}
          open={openDatePicker}
          onBlur={() => setOpenDatePicker(false)}
          selected={startDate}
          value={inputValue} // Use the raw input value for display
          dateFormat='dd/MM/yyyy'
          placeholderText='DD/MM/YYYY'
          onSelect={handleDateChange}
          onChangeRaw={(event) => handleInputChange(event)}
          className={isValidDate ? '' : 'invalid-date'}
          minDate={validation === 'gt' ? currentDate : undefined}
          maxDate={validation === 'lt' ? currentDate : undefined}
        />
      </div>
    </div>
  )
}

export default DatePickerInput
