import { Select } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

const MultipleSelect = (props) => {
  const {
    name,
    label,
    disabled,
    placeholder,
    options = [],
    value,
    handleChange,
    onSelectSearch,
    error,
  } = props
  return (
    <div className='select-dropdown'>
      {label && (
        <div className='d-flex align-items-center gap-2'>
          <label htmlFor={name}>{label}</label>
          {error && (
            <InfoCircleOutlined
              style={{ color: '#D93F21', fontSize: '1rem' }}
            />
          )}
        </div>
      )}
      <Select
        mode='multiple'
        allowClear
        style={{
          width: '100%',
        }}
        disabled={disabled}
        placeholder={placeholder || 'Seleziona'}
        value={value}
        onChange={handleChange}
        onSearch={onSelectSearch}
        options={options}
      />
    </div>
  )
}

export default MultipleSelect
