import { Modal, Button } from 'antd'

import { ReactComponent as Close } from '../svgs/close.svg'

const Popup = ({
  type,
  open,
  setOpen,
  title,
  subTitle,
  confirmTitle,
  loading,
  onConfirm,
}) => {
  return (
    <Modal
      className='modal-xs'
      title={null}
      open={open}
      onCancel={() => setOpen(false)}
      footer={null}
      closable={false}
      centered
    >
      <div className='text-center mb-5 mt-3'>
        <h3 className='text-black fs-20 fw-600 mb-3'>{title}</h3>
        <p className='text-dimmed fs-14'>{subTitle}</p>
      </div>

      <div className='d-flex justify-content-center gap-1 mb-3'>
        <Button
          className='text-black fs-6 fw-600 border-0 radius-8 py-12 px-4'
          onClick={() => setOpen(false)}
        >
          Cancella
        </Button>
        <Button
          className={`${
            type === 'danger'
              ? 'bg-danger'
              : type === 'success'
              ? 'bg-green'
              : ''
          } text-white fs-6 fw-600 border-0 radius-8 py-12 px-4 h-auto`}
          loading={loading}
          onClick={onConfirm}
        >
          {confirmTitle}
        </Button>
      </div>

      <Close className='close' onClick={() => setOpen(false)} />
    </Modal>
  )
}

export default Popup
