import { Row, Col } from 'antd'

import './LargeRadioGroup.scss'

const LargeRadioGroup = ({
  options,
  selectedOption,
  setSelectedOption,
  span = 12,
  paddingX = 3,
  paddingY = 3,
  extraClassNames,
}) => {
  return (
    <Row
      gutter={[24, 16]}
      className={`large-radio-group ${extraClassNames ? extraClassNames : ''}`}
    >
      {options.map((option, i) => (
        <Col xs={24} sm={span} key={i}>
          <div
            className={`option-wrapper ${
              selectedOption === option.value ? 'bg-green-50 active' : ''
            } rounded-3 px-${paddingX} py-${paddingY} cursor-pointer`}
            onClick={() => setSelectedOption(option.value)}
          >
            <div
              className={`outer-circle ${
                selectedOption === option.value ? 'active' : ''
              }`}
            ></div>
            <div className='d-flex gap-1'>
              {option.icon && option.icon}
              {option.secondIcon && option.secondIcon}
            </div>
            <div>
              <h5 className='fs-6 fw-bold mb-0'>{option.title}</h5>
              {option.description && (
                <p className='fs-14 text-dimmed mt-1 mb-0'>
                  {option.description}
                </p>
              )}
            </div>
          </div>
        </Col>
      ))}
    </Row>
  )
}

export default LargeRadioGroup
