import { Upload } from 'antd'
import { CameraOutlined } from '@ant-design/icons'

import './ImageUpload.scss'

const ImageUpload = ({ onChange, imageUrl }) => {
  return (
    <Upload
      className='image-upload'
      maxCount={1}
      onChange={onChange}
      beforeUpload={() => false}
      showUploadList={false}
    >
      <div className='card'>
        <div className='image' style={{ backgroundImage: `url(${imageUrl})` }}>
          <div className='icon'>
            <CameraOutlined className='text-green fs-6' />
          </div>
        </div>
      </div>
    </Upload>
  )
}

export default ImageUpload
