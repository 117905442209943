import { useState } from 'react'
import { Upload } from 'antd'
import { useTranslation } from 'react-i18next'
import { CloudUploadOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { debounce, errorHandling, openNotification } from '../../../utils/utils'
import { useUploadFile } from '../../hooks/tempUpload/useUploadFile'
import { useRemoveFile } from '../../hooks/tempUpload/useRemoveFile'
import { useDeleteProfileFile } from '../../admin-views/users/apis/useDeleteProfileFile'

import FileTabGreen from '../../../components/shared-components/FileTabGreen'
import Popup from '../../../components/shared-components/popups/Popup'

import './InstantUploadGeneral.scss'

const InstantUploadGeneral = ({
  uploadedFiles,
  uploadedFilesType,
  hideUploadedDeleteIcon,
  files,
  setFiles,
}) => {
  const { t } = useTranslation()

  const uploadMutation = useUploadFile()
  const removeMutation = useRemoveFile()
  const deleteProfileMutation = useDeleteProfileFile()

  const [progress, setProgress] = useState(0)
  const [disabled, setDisabled] = useState(false)
  const [error, setError] = useState(null)

  const [deleteData, setDeleteData] = useState(null)
  const [deleteOpen, setDeleteOpen] = useState(false)

  const handleChange = debounce((newFileList) => {
    setError(null)
    setDisabled(true)

    uploadMutation.mutate(
      {
        formData: newFileList,
        onUploadProgress: (progress) => setProgress(progress),
      },
      {
        onSuccess: (data) => {
          const paths = data.paths
          setFiles([...files, ...paths])
          setDisabled(false)
        },
        onError: (error) => {
          setError(errorHandling(error))
          setDisabled(false)
        },
      }
    )
  }, 300)

  return (
    <div className='instant-upload-general'>
      <Upload
        className='upload-general'
        multiple
        onChange={() => false}
        beforeUpload={(_, FileList) => {
          handleChange(FileList)
          return false
        }}
        disabled={disabled}
      >
        <div className='upload-input-content'>
          <CloudUploadOutlined className='fs-6' />{' '}
          {disabled && progress > 0
            ? `Caricamento file ${progress}%`
            : t('Choose File to Upload')}
        </div>
      </Upload>

      {error && (
        <span className='d-flex align-items-center gap-2 fs-14 text-danger mt-2'>
          <InfoCircleOutlined style={{ color: '#D93F21', fontSize: '1rem' }} />
          <span>{error}</span>
        </span>
      )}

      {files?.length || uploadedFiles?.length ? (
        <div className='d-flex flex-wrap align-items-center gap-2 mt-3'>
          {files?.map((path, i) => (
            <FileTabGreen
              key={i}
              index={i}
              fileName={path.split('/')[1].split('-')[5].split('.')[0]}
              fileLink={`${process.env.REACT_APP_IMG_PATH}${path}`}
              onDelete={() => {
                setError(null)
                removeMutation.mutate(path, {
                  onSuccess: () => {
                    const filteredArr = files.filter((file) => file !== path)
                    setFiles(filteredArr)
                  },
                  onError: (error) => {
                    setError(errorHandling(error))
                  },
                })
              }}
            />
          ))}

          {uploadedFiles?.map((ele, i) => (
            <FileTabGreen
              key={i}
              index={i}
              fileLink={`${process.env.REACT_APP_IMG_PATH}${ele.path}`}
              onDelete={() => {
                setDeleteData({
                  fileType: uploadedFilesType,
                  fileID: ele.id,
                })
                setDeleteOpen(true)
              }}
              hideUploadedDeleteIcon={hideUploadedDeleteIcon}
            />
          ))}
        </div>
      ) : null}

      <Popup
        type='danger'
        open={deleteOpen}
        setOpen={setDeleteOpen}
        title={t('Delete File')}
        subTitle={t('Are you sure that you want to delete this file ?')}
        confirmTitle={t('Yes, Delete')}
        loading={deleteProfileMutation.isLoading}
        onConfirm={() => {
          deleteProfileMutation.mutate(deleteData, {
            onSuccess: (data) => {
              openNotification('success', data.message)
              setDeleteOpen(false)
            },
            onError: (error) => openNotification('error', errorHandling(error)),
          })
        }}
      />
    </div>
  )
}

export default InstantUploadGeneral
