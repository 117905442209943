import { useMutation } from '@tanstack/react-query'
import { axiosFormData } from '../../../configs/APIConfig/axiosInstance'

export const useUploadFile = () => {
  return useMutation({
    mutationFn: async ({ formData, onUploadProgress }) => {
      const response = await axiosFormData.post('/upload_file', formData, {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          )
          onUploadProgress(percentCompleted)
        },
      })
      return response.data
    },
  })
}
