import { Upload } from 'antd'
import { useTranslation } from 'react-i18next'
import { CloudUploadOutlined, DeleteFilled } from '@ant-design/icons'

import './InstantUploadGeneral.scss'

const UploadGeneral = ({ multiple, files, setFiles }) => {
  const { t } = useTranslation()
  return (
    <>
      <Upload
        multiple={multiple}
        beforeUpload={(_, fileList) => {
          if (multiple) {
            setFiles([...files, ...fileList])
          } else {
            setFiles(fileList)
          }
        }}
        showUploadList={false}
      >
        <div className='upload-input-content'>
          <CloudUploadOutlined className='fs-6' /> {t('Choose File to Upload')}
        </div>
      </Upload>
      {files.length > 0 && (
        <div className='d-flex flex-wrap align-items-center mt-12'>
          {files.map((ele) => (
            <span
              key={ele.uid}
              className='bg-green-50 text-green fs-14 fw-500 text-decoration-underline text-capitalize border-green-200 p-2 radius-8 d-flex align-items-center me-2 mb-2'
            >
              {ele.name}
              <DeleteFilled
                className='cursor-pointer ms-2'
                style={{ color: '#D93F21', fontSize: '1rem' }}
                onClick={() => {
                  const newArr = files.filter(
                    (attachment) => attachment.uid !== ele.uid
                  )
                  setFiles([...newArr])
                }}
              />
            </span>
          ))}
        </div>
      )}
    </>
  )
}

export default UploadGeneral