import { useMutation } from '@tanstack/react-query'
import axios from '../../../../configs/APIConfig/axiosInstance'

export const useDeleteProfileFile = () => {
  return useMutation({
    mutationFn: async ({ fileType, fileID }) => {
      const response = await axios.delete(`/${fileType}/${fileID}`)
      return response.data
    },
  })
}
