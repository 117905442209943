import { Editor } from 'react-draft-wysiwyg'
import { InfoCircleOutlined } from '@ant-design/icons'

import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import './FreeTextEditor.scss'

const FreeTextEditor = ({
  minHeight = '500px',
  required = false,
  label,
  flag,
  name,
  value,
  onChange,
  error,
}) => {
  return (
    <div className='free-text'>
      {label && (
        <div className='d-flex align-items-center gap-2'>
          <label htmlFor={name}>
            {label} {required && <span className='text-danger'>*</span>}
          </label>
          {flag === 'it' ? (
            <img className='flag' src='/imgs/flags/italy.webp' alt='flag' />
          ) : (
            ''
          )}
          {flag === 'es' ? (
            <img className='flag' src='/imgs/flags/spain.webp' alt='flag' />
          ) : (
            ''
          )}
          {flag === 'eg' ? (
            <img className='flag' src='/imgs/flags/egypt.webp' alt='flag' />
          ) : (
            ''
          )}
          {error && <InfoCircleOutlined className='text-danger fs-6 mb-2' />}
        </div>
      )}

      <Editor
        editorState={value}
        wrapperClassName='demo-wrapper'
        editorClassName='demo-editor'
        editorStyle={{ minHeight }}
        toolbarClassName='demo-toolbar-custom'
        onEditorStateChange={(editorState) => onChange(editorState)}
        toolbar={{
          fontSize: { className: 'd-none' },
          fontFamily: {
            className: 'd-none',
          },
          list: {
            indent: { className: 'd-none' },
            outdent: { className: 'd-none' },
          },
          textAlign: {
            left: { className: 'd-none' },
            center: { className: 'd-none' },
            right: { className: 'd-none' },
            justify: { className: 'd-none' },
          },
        }}
      />
    </div>
  )
}

export default FreeTextEditor
