import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { InfoCircleOutlined } from '@ant-design/icons'

const Label = ({ required = false, label, error, buttonClick }) => {
  const { t } = useTranslation()
  return (
    <div className='d-flex justify-content-between mb-2'>
      <div className='d-flex align-items-center gap-2'>
        <label className='text-label fs-14 mb-0'>
          {label} {required ? <span className='text-danger'>*</span> : null}
        </label>
        {error && (
          <InfoCircleOutlined style={{ color: '#D93F21', fontSize: '1rem' }} />
        )}
      </div>
      {buttonClick && (
        <Button className='btn-text-green fs-14' onClick={buttonClick}>
          {t('Edit')}
        </Button>
      )}
    </div>
  )
}

export default Label
