import { useMutation } from '@tanstack/react-query'
import axios from '../../../configs/APIConfig/axiosInstance'

export const useRemoveFile = () => {
  return useMutation({
    mutationFn: async (path) => {
      const response = await axios.post('/remove_file', { path })
      return response.data
    },
  })
}