import { useRef } from 'react'
import { Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { debounce } from '../../../utils/utils'

import './SearchInput.scss'

const SearchInput = ({
  label,
  placeholder,
  name,
  size = 'md',
  setValue,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const inputRef = useRef(null)

  const searchParams = new URLSearchParams(history.location.search)

  const handleSearch = (e) => {
    const value = e.target.value

    if (!value) {
      searchParams.delete(name || 'search')
    } else {
      searchParams.set(name || 'search', value)
      searchParams.set('page', 1)
    }

    history.push({
      pathname: history.location.pathname,
      search: searchParams.toString(),
    })

    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  const debouncedHandleSearch = debounce(handleSearch, 500)

  return (
    <div className={`search-input ${size}`}>
      {label && <label htmlFor={name}>{label}</label>}
      <Input
        allowClear
        ref={inputRef}
        id={name}
        name={name}
        placeholder={placeholder || `${t('nav.search.ph')}...`}
        suffix={<SearchOutlined className='text-dimmed' />}
        onChange={(e) => {
          if (setValue) {
            setValue(e.target.value)
          } else {
            debouncedHandleSearch(e)
          }
        }}
        onPressEnter={(e) => {
          if (setValue) {
            return
          } else {
            handleSearch(e)
          }
        }}
      />
    </div>
  )
}

export default SearchInput
