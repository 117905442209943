import { useState } from 'react'
import { Image } from 'antd'
import { useTranslation } from 'react-i18next'

import PDFView from './modals/PDFView'
import { DeleteFilled } from '@ant-design/icons'

const imageTypes = ['png', 'jpeg', 'jpg', 'tiff', 'svg']
const txtTypes = ['pdf']

export default function FileTab({
  fileLink,
  fileName,
  index,
  onDelete,
  hideUploadedDeleteIcon,
}) {
  const { t } = useTranslation()

  const [pdfOpen, setPdfOpen] = useState(false)
  const [visible, setVisible] = useState(false)

  return (
    <>
      <a
        href={fileLink}
        target='_blank'
        rel='noreferrer'
        className='d-flex align-items-center gap-1 bg-green-100 text-green fs-14 fw-500 radius-8 text-center border-green px-6 py-2 d-inline-block'
        onClick={(event) => {
          event.preventDefault()
          const fileType = fileLink.split('.').slice(-1)[0]

          if (txtTypes.includes(fileType)) {
            setPdfOpen(true)
          } else if (imageTypes.includes(fileType)) {
            setVisible(true)
          } else {
            window.open(fileLink, '_blank')
          }
        }}
      >
        {fileName || `${t('Document')} ${index + 1}`}
        {onDelete && (
          <DeleteFilled
            className={`${hideUploadedDeleteIcon ? 'd-none' : ''}`}
            style={{ color: '#D93F21', fontSize: '1rem' }}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              onDelete()
            }}
          />
        )}
      </a>

      <PDFView open={pdfOpen} setOpen={setPdfOpen} link={fileLink} />

      <Image
        className='image-link'
        width={200}
        style={{ display: 'none' }}
        src={fileLink}
        preview={{
          visible,
          onVisibleChange: (value) => {
            setVisible(value)
          },
        }}
      />
    </>
  )
}
