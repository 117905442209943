import { useMutation } from '@tanstack/react-query'
import axios from '../../../../configs/APIConfig/axiosInstance'
import { useDispatch } from 'react-redux'
import { clearModal } from '../../../../redux/actions/Modal'
import { openNotification, errorHandling } from '../../../../utils/utils'

export const useExportPaymentsReport = () => {
  const dispatch = useDispatch()

  return useMutation({
    mutationFn: async (formValues) => {
      const {
        target,
        search,
        paymentable_type,
        service_slug,
        from,
        to,
        raised,
        pay_type,
        completed_profiles,
        refund_status,
      } = formValues
      const params = {}

      if (target) params.target = target
      if (search) params.search = search
      if (paymentable_type) params.paymentable_type = paymentable_type
      if (service_slug) params.service_slug = service_slug
      if (from) params.from = from
      if (to) params.to = to
      if (raised) params.raised = raised
      if (pay_type) params.pay_type = pay_type
      if (completed_profiles) params.completed_profiles = completed_profiles
      if (refund_status) params.refund_status = refund_status

      const response = await axios.get('/admin/payments/report', {
        params,
        responseType: 'blob',
      })
      return response.data
    },
    onSuccess: (data) => {
      const url = URL.createObjectURL(data)
      window.open(url)
      dispatch(clearModal())
    },
    onError: (error) => {
      const { message } = errorHandling(error)
      openNotification('error', message)
    },
  })
}
