import { useState } from 'react'
import { Row, Col, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import {
  SearchInput,
  RangePicker,
  RadioGroup,
  SelectDropdown,
} from '../../../components/form'
import { useGetServicesTitlesData } from '../../../hooks/useGetServicesTitlesData'
import { useExportPaymentsReport } from '../apis/useExportPaymentsReport'

const dateFormat = 'DD-MM-YYYY'

const DownloadPaymentReport = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  const target = searchParams.get('target') || 'user'
  const search = searchParams.get('search')
  const paymentable_type = searchParams.get('paymentable_type') || ''
  const service_slug = searchParams.get('service_slug')
  const from = searchParams.get('from_date')
  const to = searchParams.get('to_date')
  const raised = searchParams.get('raised')
  const pay_type = searchParams.get('pay_type')
  const completed_profiles = searchParams.get('completed_profiles')
  const refund_status = searchParams.get('refund_status')

  const [values, setValues] = useState({
    target,
    search,
    paymentable_type,
    service_slug,
    from,
    to,
    raised,
    pay_type,
    completed_profiles,
    refund_status,
  })

  const { data: servicesTitles } = useGetServicesTitlesData()
  const { mutate: exportPaymentsReport, isPending } = useExportPaymentsReport()

  return (
    <div id='main-form-style'>
      <Row gutter={[16, 16]}>
        <Col span={24} className='mb-2'>
          <SearchInput
            value={values.search}
            setValue={(value) =>
              setValues({
                ...values,
                search: value,
              })
            }
          />
        </Col>
        <Col md={12}>
          <RangePicker
            onChange={(dates) => {
              if (dates) {
                const formatedDates = dates.map((date) =>
                  date.format(dateFormat)
                )
                setValues({
                  ...values,
                  from: formatedDates[0],
                  to: formatedDates[1],
                })
              }
            }}
            note='È richiesto il selettore di intervallo'
          />
        </Col>
        <Col md={12}>
          <RadioGroup
            name='paymentable_type'
            options={[
              {
                label: 'Tutto',
                value: '',
              },
              {
                label: t('sidenav.Clients'),
                value: 'user',
              },
              {
                label: t('sidenav.Resellers'),
                value: 'reseller',
              },
            ]}
            value={values.target}
            setValue={(value) =>
              setValues({
                ...values,
                target: value,
              })
            }
          />
        </Col>
        <Col md={12}>
          <RadioGroup
            label='Seleziona la categoria'
            name='paymentable_type'
            options={[
              {
                label: 'Tutto',
                value: '',
              },
              {
                label: 'Servizi',
                value: 'service',
              },
              {
                label: 'Offerte',
                value: 'deal',
              },
            ]}
            value={values.paymentable_type}
            setValue={(value) =>
              setValues({
                ...values,
                paymentable_type: value,
              })
            }
          />
        </Col>
        <Col md={12}>
          <SelectDropdown
            label={t('table.filter.ph.service')}
            name='service_slug'
            disabled={values.paymentable_type === 'deal'}
            placeholder={t('table.filter.ph.service')}
            options={servicesTitles}
            value={values.service_slug}
            setValue={(value) =>
              setValues({
                ...values,
                service_slug: value,
              })
            }
          />
        </Col>
        <Col md={12}>
          <RadioGroup
            label='Invoices'
            name='raised'
            options={[
              {
                label: 'Trasmessa',
                value: '1',
              },
              {
                label: 'Non Trasmessa',
                value: '0',
              },
            ]}
            value={values.raised}
            setValue={(value) =>
              setValues({
                ...values,
                raised: value,
              })
            }
          />
        </Col>
        <Col md={12}>
          <RadioGroup
            label='Tipo di pagamento'
            name='pay_type'
            options={[
              {
                label: 'Prepaid',
                value: 'prepaid',
              },
              {
                label: 'Postpaid',
                value: 'postpaid',
              },
            ]}
            value={values.pay_type}
            setValue={(value) =>
              setValues({
                ...values,
                pay_type: value,
              })
            }
          />
        </Col>
        <Col md={12}>
          <RadioGroup
            label='Profilo Cliente'
            name='completed_profiles'
            options={[
              {
                label: 'Completo',
                value: '1',
              },
              {
                label: 'Non completo',
                value: '0',
              },
            ]}
            value={values.completed_profiles}
            setValue={(value) =>
              setValues({
                ...values,
                completed_profiles: value,
              })
            }
          />
        </Col>
        <Col md={12}>
          <RadioGroup
            label='Stato del rimborso'
            name='refund_status'
            options={[
              {
                label: 'Pending',
                value: 'Pending',
              },
              {
                label: 'Approvato',
                value: 'Approved',
              },
              {
                label: 'Respinto',
                value: 'Rejected',
              },
            ]}
            value={values.refund_status}
            setValue={(value) =>
              setValues({
                ...values,
                refund_status: value,
              })
            }
          />
        </Col>
      </Row>

      <div className='d-flex justify-content-end gap-3 mt-4'>
        <Button
          className='btn-green-outlined fs-14 fw-600 px-5 py-2 rounded'
          type='primary'
          loading={isPending}
          onClick={() => setValues({})}
        >
          {t('Reset')}
        </Button>
        <Button
          className='btn-green fs-14 fw-600 px-4 py-2 rounded'
          type='primary'
          disabled={!values.from && !values.to}
          loading={isPending}
          onClick={() => exportPaymentsReport(values)}
        >
          {t('Download')}
        </Button>
      </div>
    </div>
  )
}

export default DownloadPaymentReport
