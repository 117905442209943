import { Button, Modal } from 'antd'

import { ReactComponent as Close } from '../svgs/close.svg'

export default function PDFView({ open, setOpen, link }) {
  return (
    <Modal
      className='pdf-modal'
      title={null}
      open={open}
      onCancel={() => setOpen(false)}
      footer={null}
      closable={false}
      centered
      width={'100%'}
    >
      <iframe
        title={link}
        src={link}
        style={{ width: '100%', height: '100vh' }}
        frameborder='0'
      ></iframe>

      <Button
        className='close'
        onClick={() => setOpen(false)}
        type='primary'
        shape='circle'
        size='large'
        danger
      >
        <Close className='width-16' />
      </Button>
    </Modal>
  )
}
